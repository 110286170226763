<template>
  <ba-dialog
    :save="save"
    :title="$t('_connectSageIntacct')"
    :canSave="!canSave"
    :show-button="false"
    :show="showDialog"
    :onHide="hideDialog"
  >
    <v-container fluid grid-list-xl>
      <v-layout wrap align-center>
        <v-flex xs6 sm6 md6>
          <v-text-field label="Company ID" v-model="companyId"></v-text-field>
          <v-text-field label="Entity ID" v-model="entityId" v-if="!checkFeatureFlag('subsidiaries')"></v-text-field>
          <v-text-field label="Webservice User" v-model="userId"></v-text-field>
          <v-text-field label="Webservice Password" v-model="userPassword"></v-text-field>
          <v-text-field label="Digital Asset Account Code" v-model="assetAccountCode"></v-text-field>
          <v-text-field
            label="Digital Asset Account GL Code"
            v-model="sageDigitalAssetAccountCode"
            v-if="!checkFeatureFlag('subsidiaries')"
          ></v-text-field>
          <v-text-field label="Fee Account Code" v-model="feeAccountCode"></v-text-field>
          <v-text-field label="Base Currency" v-model="baseCurrency"></v-text-field>
          <v-select
            label="Subsidiary"
            :items="subs"
            v-model="subsidiaryId"
            hint="Subsidiary"
            item-text="name"
            item-value="id"
            v-if="checkFeatureFlag('subsidiaries')"
          ></v-select>
        </v-flex>
      </v-layout>
    </v-container>
  </ba-dialog>
</template>

<script>
import gql from 'graphql-tag';

import { baConfig } from '../../../config';
import { OrganizationsApi } from '../../../generated/api-svc';
export default {
  name: 'ConnectSageIntacct',
  props: ['refresh'],
  data() {
    return {
      isLoading: false,
      subsidiaryId: null,
      subs: [],
      companyId: '',
      entityId: '',
      userId: '',
      userPassword: '',
      assetAccountCode: '',
      sageDigitalAssetAccountCode: '',
      feeAccountCode: '',
      baseCurrency: '',
      showDialog: false,
    };
  },
  computed: {
    canSave() {
      // need subsidiary id if feature flag is on
      if (this.checkFeatureFlag('subsidiaries', this.features)) {
        return (
          this.companyId &&
          this.userId &&
          this.userPassword &&
          this.assetAccountCode &&
          this.feeAccountCode &&
          this.subsidiaryId
        );
      }
      return this.companyId && this.userId && this.userPassword && this.assetAccountCode && this.feeAccountCode;
    },
  },
  watch: {
    showDialog() {
      if (this.showDialog) {
        this.resetForm();
      }
    },
  },
  async mounted() {
    this.isLoading = true;
    const orgId = this.$store.state.currentOrg.id;
    if (this.checkFeatureFlag('subsidiaries', this.features)) {
      try {
        const orgsApi = new OrganizationsApi(undefined, baConfig.getFriendlyApiUrl());
        const resp = await orgsApi.listSubsidiaries(orgId, { withCredentials: true });
        if (resp.status === 200) {
          this.subs = resp.data;
        } else {
          throw new Error('Problem loading subs');
        }
      } catch (e) {
        this.showErrorSnackbar('failed to load subsidiaries');
      } finally {
        this.isLoading = false;
      }
    }
  },
  methods: {
    resetForm() {
      this.companyId = '';
      this.entityId = undefined;
      this.userId = '';
      this.userPassword = '';
      this.assetAccountCode = '';
      this.sageDigitalAssetAccountCode = undefined;
      this.feeAccountCode = '';
      this.baseCurrency = '';
      this.subsidiaryId = null;
    },
    hideDialog() {
      this.showDialog = false;
    },
    show() {
      this.showDialog = true;
    },
    save() {
      const vars = {
        orgId: this.$store.state.currentOrg.id,
        create: {
          subsidiaryId: this.subsidiaryId,
          createSageIntacct: {
            companyId: this.companyId,
            entityId: this.entityId,
            userId: this.userId,
            userPassword: this.userPassword,
            assetAccountCode: this.assetAccountCode,
            sageDigitalAssetAccountCode: this.sageDigitalAssetAccountCode,
            feeAccountCode: this.feeAccountCode,
            baseCurrency: this.baseCurrency,
          },
        },
      };
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation CreateConnection($orgId: ID!, $create: CreateConnectionInput!) {
              createConnection(orgId: $orgId, create: $create) {
                success
                errors
                message
              }
            }
          `,
          // Parameters
          variables: vars,
        })
        .then(() => {
          this.resetForm();
          if (this.refresh) {
            this.refresh();
          }
          this.showDialog = false;
        });
    },
  },
};
</script>
